import * as React from 'react';
import { FC } from 'react';
import { DateField, useTranslate, TextField, SelectField } from 'react-admin';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, Box } from '@material-ui/core';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonIcon from '@material-ui/icons/Person';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { makeStyles } from '@material-ui/core/styles';

import { Record } from 'ra-core';
import promoBox from '../data/promoBox';
// import { Order as OrderRecord, Review as ReviewRecord } from '../types';

const useAsideStyles = makeStyles(theme => ({
    root: {
        width: 400,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}));

interface AsideProps {
    record?: Record;
    basePath?: string;
}

const Aside: FC<AsideProps> = ({ record, basePath }) => {
    const classes = useAsideStyles();
    return (
        <div className={classes.root}>
            {record && <Details record={record} basePath={basePath} />}
        </div>
    );
};

Aside.propTypes = {
    record: PropTypes.any,
    basePath: PropTypes.string,
};

interface EventListProps {
    record?: Record;
    basePath?: string;
}
const Details: FC<EventListProps> = ({ record, basePath }) => {
    const translate = useTranslate();

    return (
        <>
            <Box m="0 0 1em 1em">
                <Card>
                    {/* <CardMedia
                        className={classes.media}
                        image={
                            process.env.REACT_APP_PUBLIC_URL + record?.imageUrl
                        }
                        title="Paella dish"
                    /> */}
                    <CardContent>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <PersonIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.packs.fields.created_by'
                                            )}
                                        </Typography>
                                        <TextField
                                            record={record}
                                            source="created_by.name.full"
                                            label="resources.packs.fields.created_by"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <ListAltIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.users.fields.promoBox'
                                            )}
                                        </Typography>
                                        <TextField
                                            record={record}
                                            source="created_by.promoBox"
                                            label="resources.users.fields.promoBox"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.packs.fields.created_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            locales="fr-FR"
                                            source="created_at"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessTimeIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate(
                                                'resources.packs.fields.updated_at'
                                            )}
                                        </Typography>
                                        <DateField
                                            record={record}
                                            locales="fr-FR"
                                            source="updated_at"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default Aside;

import { TranslationMessages } from 'ra-core';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Login',
            password: 'Password',
            sign_in_error: 'Signin Failed',
        },
    },
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Bienvenu chez Sothema',
                subtitle:
                    'SOTHEMA est un laboratoire pharmaceutique 100% marocain, spécialisé dans la fabrication et la commercialisation des médicaments depuis 1976. SOTHEMA représente 35 laboratoires commettants parmi les leaders au Monde dans la Recherche & Développement.',
                aor_button: 'Sothema site',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            users: 'Users',
            annuaire: 'Annuaire',
        },
    },
    resources: {
        users: {
            name: 'Commercial |||| Commerciaux',
            fields: {
                firstName: 'Prénom',
                lastName: 'Nom',
                email: 'Email',
                emailValidated: 'Email validé',
                promoBox: 'Boite de promo',
                accountEnabled: 'Activé',
                accountEnabledByAdmin: 'Activé par admin',
                isRegional: 'Responsable régional',
                isNational: 'Responsable national',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                priceCategory: 'Tranche de prix',
                additionalRegions: 'Regions additionelles',
                commands: 'Commandes',
                groups: 'Segments',
                total_spent: 'Dépenses',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        admins: {
            name: 'Administrator |||| Administrators',
            fields: {
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email',
                phone: 'Phone',
                image: 'Image',
                address1: 'Adresse',
                city: 'City',
                country: 'Country',
                socialReason: 'Social Reason',
                created_at: 'Created At',
                updated_at: 'Updated At',
                priceCategory: 'Price Category',

                commands: 'Orders',
                groups: 'Segments',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                image: 'Image',
                identity: 'Identity',
                phone: 'Phone',
                address: 'Address',
                pricing: 'Pricing',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        magasiniers: {
            name: 'Magasinier |||| Magasiniers',
            fields: {
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email',
                phone: 'Phone',
                image: 'Image',
                address1: 'Adresse',
                city: 'City',
                country: 'Country',
                socialReason: 'Social Reason',
                created_at: 'Created At',
                updated_at: 'Updated At',
                priceCategory: 'Price Category',
                currentSite: 'Current Site',

                commands: 'Orders',
                groups: 'Segments',
                total_spent: 'Total spent',
                password: 'Password',
                confirm_password: 'Confirm password',
            },
            filters: {
                last_visited: 'Last visited',
                today: 'Today',
                this_week: 'This week',
                last_week: 'Last week',
                this_month: 'This month',
                last_month: 'Last month',
                earlier: 'Earlier',
                has_ordered: 'Has ordered',
                has_newsletter: 'Has newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                image: 'Image',
                identity: 'Identity',
                site: 'Current Site ',
                phone: 'Phone',
                address: 'Address',
                pricing: 'Pricing',
                history: 'History',
                password: 'Password',
                change_password: 'Change Password',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        packs: {
            name: 'Demande |||| Demandes',
            fields: {
                pharmacy: 'Pharmacie',
                status: 'Statut',
                justificatif: 'Justificatif',
                justificatifStatus: 'Statut du justificatif',
                images: 'Images',
                nature: 'Nature',
                city: 'Ville',
                region: 'Région',
                phone: 'Tél',
                number: 'Nombre de Packs',
                quantity: 'Quantité',
                bl: 'Numéro BL',
                bl1: 'Numéro BL 2',
                bl2: 'Numéro BL 3',
                bl3: 'Numéro BL 4',
                grossiste: 'Grossiste',
                montantBonAchat: "Montant Bon d'Achat",
                date: 'Date BL',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                created_by: 'Commercial',
                priceCategory: 'Tranche de prix',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                image: 'Image',
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        archive: {
            name: 'Archive |||| Archive',
            fields: {
                pharmacy: 'Pharmacie',
                status: 'Statut',
                justificatif: 'Justificatif',
                justificatifStatus: 'Statut du justificatif',
                images: 'Images',
                nature: 'Nature',
                city: 'Ville',
                region: 'Région',
                phone: 'Tél',
                number: 'Nombre de Packs',
                quantity: 'Quantité',
                bl: 'Numéro BL',
                grossiste: 'Grossiste',
                montantBonAchat: "Montant Bon d'Achat",
                date: 'Date BL',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                created_by: 'Commercial',
                priceCategory: 'Tranche de prix',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                image: 'Image',
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            image: {
                upload_several:
                    'Glissez des photos à uploader ou cliquez pour en sélectionner une.',
                upload_single:
                    'Glissez une photo à uploader ou cliquez pour la sélectionner.',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        promobox: {
            name: 'Boite de promo |||| Boites de promo',
            fields: {
                name: 'Nom',
                products: 'Produits',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
            },
        },
    },
};

export default customEnglishMessages;

import React, { SVGProps } from 'react';
import { SvgIcon } from '@material-ui/core';
const SituationIcon = (props: SVGProps<SVGSVGElement>) => (
    <SvgIcon>
        <g>
            <path d="m11 13.75c0-.413-.338-.75-.75-.75h-3.75v3c0 .552-.448 1-1 1s-1-.448-1-1v-3h-3.75c-.413 0-.75.337-.75.75v9.5c0 .413.337.75.75.75h9.5c.412 0 .75-.337.75-.75z" />
            <path d="m24 13.75c0-.413-.338-.75-.75-.75h-3.75v3c0 .552-.448 1-1 1s-1-.448-1-1v-3h-3.75c-.413 0-.75.337-.75.75v9.5c0 .413.337.75.75.75h9.5c.412 0 .75-.337.75-.75z" />
            <path d="m17.5.75c0-.412-.338-.75-.75-.75h-3.75v3c0 .552-.448 1-1 1s-1-.448-1-1v-3h-3.75c-.413 0-.75.338-.75.75v9.5c0 .413.337.75.75.75h9.5c.412 0 .75-.337.75-.75z" />
        </g>
    </SvgIcon>
);

export default SituationIcon;

export default [
    {
        id: 'empty',
        name: 'Vide',
    },
    {
        id: 'required',
        name: 'Obligatoire',
    },
    {
        id: 'new',
        name: 'Nouveau',
    },
    {
        id: 'accepted',
        name: 'Accepté',
    },
    {
        id: 'refused',
        name: 'Refusé',
    },
];

import * as React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SearchInput,
    SelectInput,
} from 'react-admin';

import ProductsField from './ProductsFiled';

import promoBox from '../data/promoBox';

const PromoBoxFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" resettable alwaysOn />

        <SelectInput
            source="name"
            label="resources.promobox.fields.name"
            choices={promoBox}
            optionText="name"
            optionValue="id"
            alwaysOn
        />
    </Filter>
);

const PromoBoxList = (props: any) => {
    // const classes = useStyles();

    return (
        <List
            {...props}
            filters={<PromoBoxFilter />}
            sort={{ field: 'date', order: 'DESC' }}
            // perPage={25}
            bulkActionButtons={false}
            exporter={false}
            // aside={<PromoBoxListAside />}
        >
            <Datagrid optimized rowClick="edit">
                {/* <PromoBoxLinkField /> */}
                <TextField
                    source="name"
                    label="resources.promobox.fields.name"
                />
                <ProductsField />

                <DateField source="created_at" type="date" locales="fr-FR" />
            </Datagrid>
        </List>
    );
};

export default PromoBoxList;

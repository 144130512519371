import React, { useState, useCallback } from 'react';
import {
    Button,
    SaveButton,
    SimpleForm,
    FileInput,
    required,
    FileField,
    Toolbar,
    useNotify,
    useDataProvider,
    fetchEnd,
    fetchStart,
    CREATE,
    useRefresh,
    useRedirect,
} from 'react-admin';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useFormState } from 'react-final-form';

const AcceptJustificatisButton = props => {
    const { handleCloseDialog, record, filterValues } = props;

    const [btnDisabled, setBtnDisabled] = useState(false);
    const refresh = useRefresh();
    const redirectTo = useRedirect();
    const notify = useNotify();
    const formState = useFormState();
    const dataProvider = useDataProvider();
    const handleClick = useCallback(() => {
        if (!formState.valid) {
            return;
        }

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();
        setBtnDisabled(true);

        dataProvider(CREATE, 'packs/justifs', {
            data: { $filter: filterValues },
        })
            .then(response => {
                const { data } = response;
                const count = data && data.count ? data.count : 0;
                // Refresh the choices of the ReferenceInput to ensure our newly
                notify(`Demande archivée`, 'info', {
                    smart_count: 1,
                });
            })
            .catch(error => {
                const errorMsg = error.message
                    ? error.message
                    : 'Demande non archivée';
                notify(errorMsg, 'warning');
            })
            .finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                fetchEnd();
                setBtnDisabled(false);
                //close dilog
                handleCloseDialog();
                // refresh();
                // refresh();
                redirectTo('/packs');
            });
    }, [
        formState.valid,
        formState.values,
        dataProvider,
        notify,
        handleCloseDialog,
    ]);

    return (
        <SaveButton
            {...props}
            disabled={btnDisabled}
            icon={<AttachFileIcon />}
            handleSubmitWithRedirect={handleClick}
        />
    );
};

export default props => {
    const { record } = props;

    // const { selectedIds } = props;
    // userIds = selectedIds;
    const [showDialog, setShowDialog] = React.useState(false);

    const handleOpenDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => setShowDialog(false);

    const AacceptJustificatifsToolbar = props => (
        <Toolbar
            {...props}
            record={record}
            style={{ backgroundColor: 'white' }}
        >
            <AcceptJustificatisButton
                handleCloseDialog={handleCloseDialog}
                label="Accepter Justificatifs"
                submitOnEnter={false}
                variant="text"
                record={record}
                filterValues={props.filterValues}
            />
            <Button label="Annuler" variant="text" onClick={handleCloseDialog}>
                <IconCancel />
            </Button>
        </Toolbar>
    );

    return (
        <>
            <Button onClick={handleOpenDialog} label="Accepter Justif">
                <AttachFileIcon />
            </Button>

            <Dialog
                fullWidth
                open={showDialog}
                // onClose={handleCloseDialog}
                aria-label="Importer des utilisateurs"
            >
                <DialogTitle>
                    ACcepter Tous les "Nouveaux" justificatifs des demandes
                    Sélectionnées
                </DialogTitle>
                <DialogContent>
                    {/* <Create basePath={'/users'} resource={'users'}> */}
                    <SimpleForm
                        submitOnEnter={false}
                        resource="packs"
                        // save={handleSubmit}
                        toolbar={<AacceptJustificatifsToolbar {...props} />}
                        record={record}
                    >
                        {/* <ValidationBulkForm {...props}/> */}
                    </SimpleForm>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </>
    );
};

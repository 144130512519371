import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    FormWithRedirect,
    required,
    email,
    SelectInput,
    BooleanInput,
    SelectArrayInput,
    FormDataConsumer,
} from 'react-admin';
import { Box, Card, CardContent } from '@material-ui/core';

import Aside from './Aside';
import FullNameField from './FullNameField';
import { validatePasswords } from './UserCreate';
import { Customer, FieldProps } from '../types';
import regions from '../data/regions';
import promoBox from '../data/promoBox';

const UserEdit = (props: any) => {
    return (
        <Edit
            title={<UserTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <UserForm />
        </Edit>
    );
};

const UserTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

const UserForm = (props: any) => {
    return (
        <FormWithRedirect
            {...props}
            validate={validatePasswords}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.image'
                                        )}
                                    </Typography> */}
                                    {/* <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <ImageInput
                                                source="image"
                                                label="resources.users.fields.image"
                                                labelSingle="resources.users.image.upload_single"
                                                accept="image/*"
                                                multiple={false}
                                                fullWidth={true}
                                            >
                                                <ImageField
                                                    accept="image/*"
                                                    source="imageUrl"
                                                    title="title"
                                                />
                                            </ImageInput>
                                        </Box>
                                    </Box> */}
                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.identity'
                                        )}
                                    </Typography> */}
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name.first"
                                                resource="users"
                                                label="resources.users.fields.firstName"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name.last"
                                                resource="users"
                                                label="resources.users.fields.lastName"
                                                validate={requiredValidate}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <TextInput
                                        type="email"
                                        source="email"
                                        label="resources.users.fields.email"
                                        resource="users"
                                        validate={[email(), required()]}
                                        fullWidth
                                    />
                                    <SelectInput
                                        source="region"
                                        label="resources.packs.fields.region"
                                        choices={regions}
                                        optionText="name"
                                        optionValue="id"
                                        alwaysOn
                                    />

                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            formData && formData.isRegional ? (
                                                <SelectArrayInput
                                                    source="additionalRegions"
                                                    label="resources.users.fields.additionalRegions"
                                                    choices={regions}
                                                    optionText="name"
                                                    optionValue="id"
                                                    alwaysOn
                                                />
                                            ) : null
                                        }
                                    </FormDataConsumer>

                                    <SelectInput
                                        source="promoBox"
                                        label="resources.users.fields.promoBox"
                                        choices={promoBox}
                                        resource="users"
                                        validate={[required()]}
                                        fullWidth
                                    />

                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <BooleanInput
                                                source="accountEnabled"
                                                label="resources.users.fields.accountEnabled"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <BooleanInput
                                                source="isRegional"
                                                label="resources.users.fields.isRegional"
                                                fullWidth
                                            />
                                            <BooleanInput
                                                source="isNational"
                                                label="resources.users.fields.isNational"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    {/* <TextInput
                                        type="phone"
                                        source="phone"
                                        label="resources.users.fields.phone"
                                        resource="users"
                                        // validate={requiredValidate}
                                        fullWidth
                                    /> */}

                                    {/* <Box mt="1em" /> */}

                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.address'
                                        )}
                                    </Typography> */}
                                    {/* <TextInput
                                        source="address1"
                                        resource="users"
                                        label="resources.users.fields.address1"
                                        multiline
                                        fullWidth
                                        helperText={false}
                                    /> */}
                                    {/* <TextInput
                                        source="city"
                                        label="resources.users.fields.city"
                                        resource="users"
                                        fullWidth
                                        helperText={false}
                                    /> */}

                                    {/* <Box mt="1em" /> */}
                                    {/*
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.change_password'
                                        )}
                                    </Typography> */}
                                    {/* <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="password"
                                                label="resources.users.fields.password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <PasswordInput
                                                source="confirmPassword"
                                                label="resources.users.fields.confirm_password"
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box> */}
                                </Box>
                            </Box>
                        </CardContent>

                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={false}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="users"
                        >
                            <SaveButton {...formProps} />
                            <DeleteButton {...formProps} />
                        </Toolbar>
                    </form>
                </Card>
            )}
        />
    );
};

const requiredValidate = [required()];

export default UserEdit;

export default [
    {
        id: 'vm',
        name: 'VM',
    },
    {
        id: 'telephoniste',
        name: 'Téléphoniste',
    },
    {
        id: 'commande_directe',
        name: 'Commande Directe',
    },
];

import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';

import { Region } from '../../types';

interface PackStats {
    date: String;
    packsCount: number;
}
interface State {
    regions?: [Region];
    packStats?: PackStats[];
}
const getCardTitle = lastyear => {
    let year = new Date().getFullYear();
    if (lastyear) year -= 1;

    return `Quantités saisies ${year}`;
};

const MonthlyQuantitiesPerRegion = ({
    region,
    height = 250,
    lastyear = false,
}) => {
    const [packStats, setPackStats] = useState([]);

    const version = useVersion();
    const dataProvider = useDataProvider();

    console.log('MonthlyQuantitiesPerRegion');
    console.log({ lastyear });

    const fetchStats = useCallback(async () => {
        const { data: packStats } = await dataProvider.getList(
            'packs/region/monthlyqtystats',
            {
                filter: { region, lastyear },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 50 },
            }
        );

        setPackStats(packStats);
    }, [dataProvider, region]);

    useEffect(() => {
        fetchStats();
    }, [version, region]);

    return (
        <Card>
            <CardHeader title={getCardTitle(lastyear)} subheader="Par mois" />

            <CardContent>
                <ResponsiveContainer width="100%" height={height}>
                    <BarChart
                        width={150}
                        height={50}
                        data={packStats}
                        margin={{
                            top: 15,
                            right: 15,
                            left: 15,
                            bottom: 25,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="month"
                            label={{
                                value: '',
                                position: 'insideBottomRight',
                                offset: -1,
                            }}
                        />
                        <XAxis
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            // tick={renderQuarterTick}
                            height={100}
                            scale="band"
                            // xAxisId="quarter"
                            // label={{
                            //     value: '',
                            //     angle: -90,
                            //     position: 'insideLeft',
                            // }}
                        />
                        <YAxis />
                        <Tooltip itemSorter={item => -item.value} />
                        <Legend />
                        <Bar
                            dataKey={'tanger'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'casablanca'}
                            fill="#c182ca"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'rabat'}
                            fill="#5234eb"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'marrakech'}
                            fill="#eb34c9"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'agadir'}
                            fill="#43eb34"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'oriental'}
                            fill="#dfeb34"
                            // label={{ position: 'top' }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default MonthlyQuantitiesPerRegion;

import PromoBoxIcon from '@material-ui/icons/Business';

import PromoBoxList from './PromoBoxList';
// import PromoBoxCreate from './PromoBoxCreate';
import PromoBoxEdit from './PromoBoxEdit';

export default {
    list: PromoBoxList,
    // create: PromoBoxCreate,
    edit: PromoBoxEdit,
    icon: PromoBoxIcon,
};

import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    required,
    ImageInput,
    ImageField,
    email,
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import Aside from './Aside';

export const styles: Styles<Theme, any> = {
    first_name: { display: 'inline-block', width: '48%' },
    last_name: { display: 'inline-block', width: '48%', marginLeft: '4%' },
    email: { width: '100%' },
    address: { width: '100%' },
    city: { width: '100%' },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block', width: '48%' },
    confirm_password: {
        display: 'inline-block',
        width: '48%',
        marginLeft: '4%',
    },
};

const useStyles = makeStyles(styles);

export const validatePasswords = ({
    password,
    confirmPassword,
}: {
    password: string;
    confirmPassword: string;
}) => {
    const errors = {} as any;

    if (
        (password && confirmPassword && password !== confirmPassword) ||
        (!password && confirmPassword) ||
        (password && !confirmPassword)
    ) {
        errors.confirmPassword = ['resources.users.errors.password_mismatch'];
    }

    return errors;
};

const UserCreate = (props: any) => {
    const classes = useStyles();

    return (
        <Create {...props} aside={<Aside />}>
            <SimpleForm validate={validatePasswords} redirect="list">
                <SectionTitle label="resources.users.fieldGroups.image" />
                <ImageInput
                    source="image"
                    label="resources.users.fields.image"
                    labelSingle="resources.users.image.upload_single"
                    accept="image/*"
                    multiple={false}
                    fullWidth={true}
                >
                    <ImageField
                        accept="image/*"
                        source="imageUrl"
                        title="title"
                    />
                </ImageInput>
                <SectionTitle label="resources.users.fieldGroups.identity" />
                <TextInput
                    autoFocus
                    source="name.first"
                    label="resources.users.fields.firstName"
                    formClassName={classes.first_name}
                    validate={requiredValidate}
                    fullWidth
                />
                <TextInput
                    source="name.last"
                    label="resources.users.fields.lastName"
                    formClassName={classes.last_name}
                    validate={requiredValidate}
                    fullWidth
                />
                <TextInput
                    type="email"
                    label="resources.users.fields.email"
                    source="email"
                    helperText={false}
                    validation={{ email: true }}
                    fullWidth
                    formClassName={classes.email}
                    validate={[required(), email()]}
                />
                <TextInput
                    type="phone"
                    label="resources.users.fields.phone"
                    source="phone"
                    fullWidth
                    formClassName={classes.email}
                />
                {/* <DateInput source="birthday" /> */}
                <Separator />
                <SectionTitle label="resources.users.fieldGroups.address" />
                <TextInput
                    source="address1"
                    label="resources.users.fields.address1"
                    formClassName={classes.address}
                    multiline
                    fullWidth
                    helperText={false}
                />
                {/* <TextInput
                    source="zipcode"
                    label="resources.users.fields.zipcode"
                    formClassName={classes.zipcode}
                    helperText={false}
                /> */}
                <TextInput
                    source="city"
                    label="resources.users.fields.city"
                    formClassName={classes.city}
                    fullWidth
                    helperText={false}
                />
                <Separator />
                <SectionTitle label="resources.users.fieldGroups.password" />
                <PasswordInput
                    source="password"
                    label="resources.users.fields.password"
                    formClassName={classes.password}
                    fullWidth
                />
                <PasswordInput
                    source="confirmPassword"
                    label="resources.users.fields.confirm_password"
                    formClassName={classes.confirm_password}
                    fullWidth
                />
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default UserCreate;

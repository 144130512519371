import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { RegionDashboard } from './RegionDashboard';
import { GrossisteDashboard } from './GrossisteDashboard';
import { NatureDashboard } from './NatureDashboard';
import { PromoBoxDashboard } from './PromoBoxDashboard';
import { GlobalDashboard } from './GlobalDashboard';
import { CumulPromoBoxDashboard } from './CumulPromoBoxDashboard';
import { EvolutionCumulPromoBoxDashboard } from './EvolutionCumulPromoBoxDashboard';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        width: '100%',
        // backgroundColor: 'green',
        // marginTop:'0em',

        // padding:'0 !important',
        // // backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonForce() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    <Tab label="Volume Par Région" wrapped {...a11yProps(0)} />
                    <Tab
                        label="Volume par Grossiste"
                        wrapped
                        {...a11yProps(1)}
                    />
                    <Tab label="Volume par Nature" wrapped {...a11yProps(2)} />
                    <Tab
                        label="Volume par boîte de promo"
                        wrapped
                        {...a11yProps(3)}
                    />
                    <Tab label="Evolution" wrapped {...a11yProps(4)} />
                    <Tab label="Boite de promo" wrapped {...a11yProps(5)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <RegionDashboard />
                <RegionDashboard lastyear={true} />
            </TabPanel>
            1
            <TabPanel value={value} index={1}>
                <GrossisteDashboard />
                <GrossisteDashboard lastyear={true} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <NatureDashboard />
                <NatureDashboard lastyear={true} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <PromoBoxDashboard />
                <PromoBoxDashboard lastyear={true} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <EvolutionCumulPromoBoxDashboard lastyear={false} />
                <EvolutionCumulPromoBoxDashboard lastyear={true} />
                {/* <GlobalDashboard lastyear={false} />
                <GlobalDashboard lastyear={true} /> */}
            </TabPanel>
            <TabPanel value={value} index={5}>
                <CumulPromoBoxDashboard />
                <CumulPromoBoxDashboard lastyear={true} />
            </TabPanel>
        </div>
    );
}

export default [
    {
        id: 'tanger',
        name: 'Tanger et régions',
    },
    {
        id: 'casablanca',
        name: 'Casablanca et régions',
    },
    {
        id: 'rabat',
        name: 'Rabat et régions',
    },
    {
        id: 'marrakech',
        name: 'Marrakech et régions',
    },
    {
        id: 'agadir',
        name: 'Agadir et régions',
    },
    {
        id: 'oriental',
        name: 'Fes, Meknes et Oriental',
    },
];

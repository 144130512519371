// import ArchiveIcon from './ArchiveIcon';
import ArchiveIcon from '@material-ui/icons/Archive';
import ArchiveList from './ArchiveList';
// import ArchiveCreate from './ArchiveCreate';
import ArchiveEdit from './ArchiveEdit';

export default {
    list: ArchiveList,
    // create: ArchiveCreate,
    edit: ArchiveEdit,
    icon: ArchiveIcon,
};

import PackIcon from './PackIcon';

import PackList from './PackList';
// import PackCreate from './PackCreate';
import PackEdit from './PackEdit';

export default {
    list: PackList,
    // create: PackCreate,
    edit: PackEdit,
    icon: PackIcon,
};

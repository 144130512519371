export default [
    {
        id: 'ELLAONE',
        name: 'ELLAONE',
    },
    {
        id: 'ACTICARBINE',
        name: 'ACTICARBINE',
    },
    {
        id: 'CLORACEF',
        name: 'CLORACEF',
    },
    {
        id: 'NALGESIC',
        name: 'NALGESIC',
    },
];

import React, { FC, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
const FileSaver = require('file-saver');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        title: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
    })
);
const PackJustificatifGrid: FC<any> = ({ record, index, thumbnail = true }) => {
    // { record,index ,thumbnail=true}
    // const rootClasses=useStyles();
    // const propsClasses=makeStyles({root:style});
    const [selectedImg, setSelectedImg] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);


    const onImageChange = index => {
        setSelectedImg(index);
    };
    const downloadImg = () => {
        const img = downloadUrls[photoIndex];
        const imgUrl = `${process.env.REACT_APP_PUBLIC_URL}${img}`;

        FileSaver.saveAs(imgUrl, `image${photoIndex}.jpg`);
    };

    const classes = useStyles();

    // const imageUrls = record && record.imageUrls ? record.imageUrls : [];


        const downloadUrls =
        record && record.justificatifUrl ? [record.justificatifUrl] : [];

    return (
        <div className={classes.root}>
               <div>
                   {
                      downloadUrls.length?
                      <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setIsOpen(true)}
                  >
                      Jutificatif
             </Button>:
             <h2>Aucun Justificatif</h2>
                   }



        {isOpen && (
          <Lightbox
            mainSrc={`${process.env.REACT_APP_PUBLIC_URL}${downloadUrls[photoIndex]}`}
            nextSrc={`${process.env.REACT_APP_PUBLIC_URL}${downloadUrls[(photoIndex + 1) % downloadUrls.length]}`}
            prevSrc={`${process.env.REACT_APP_PUBLIC_URL}${downloadUrls[(photoIndex + downloadUrls.length - 1) % downloadUrls.length]}`}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + downloadUrls.length - 1) % downloadUrls.length)
            }
            onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % downloadUrls.length)
            }
            toolbarButtons= {[ <Button
                variant="contained"
                color="primary"
                onClick={downloadImg}
            >
                Télécharger
            </Button>] }
          />
        )}
      </div>


        </div>
    );
};

export default PackJustificatifGrid;

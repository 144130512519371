import * as React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SelectField,
    SearchInput,
} from 'react-admin';

import ColoredBooleanField from './ColoredBooleanField';
import regions from '../data/regions';
import promoBox from '../data/promoBox';

const FileSaver = require('file-saver');

const exporter = (records, fetchRelatedRecords) => {
    const excelUrl = `${process.env.REACT_APP_ADMIN_REST_API_URL}/users/excel`;
    FileSaver.saveAs(excelUrl, 'liste des commerciaux.xlsx');
};

const UserFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" resettable alwaysOn />
    </Filter>
);

const UserList = (props: any) => {
    return (
        <List
            {...props}
            filters={<UserFilter />}
            sort={{ field: 'updated_at', order: 'DESC' }}
            // perPage={25}
            bulkActionButtons={false}
            exporter={exporter}
            // aside={<UserListAside />}
        >
            <Datagrid optimized rowClick="edit">
                {/* <UserLinkField /> */}
                <TextField
                    source="name.last"
                    label="resources.users.fields.lastName"
                />
                <TextField
                    source="name.first"
                    label="resources.users.fields.firstName"
                />
                <SelectField
                    source="region"
                    label="resources.packs.fields.region"
                    choices={regions}
                    optionText="name"
                    optionValue="id"
                />
                <TextField
                    source="email"
                    label="resources.users.fields.email"
                />

                {/* <SelectField
                    source="emailValidated"
                    label="resources.users.fields.emailValidated"
                    choices={[
                        { id: false, name: 'Non' },
                        { id: true, name: 'Oui' },
                    ]}
                /> */}
                <ColoredBooleanField
                    source="emailValidated"
                    label="resources.users.fields.emailValidated"
                />
                {/* <SelectField
                    source="accountEnabled"
                    label="resources.users.fields.accountEnabledByAdmin"
                    choices={[
                        { id: true, name: 'Oui' },
                        { id: false, name: 'Non' },
                    ]}
                /> */}
                <ColoredBooleanField
                    source="accountEnabled"
                    label="resources.users.fields.accountEnabled"
                />
                {/* <SelectField
                    source="isRegional"
                    label="resources.users.fields.isRegional"
                    choices={[
                        { id: true, name: 'Oui' },
                        { id: false, name: 'Non' },
                    ]}
                /> */}
                <ColoredBooleanField
                    source="isRegional"
                    label="resources.users.fields.isRegional"
                />

                {/* <SelectField
                    source="isNational"
                    label="resources.users.fields.isNational"
                    choices={[
                        { id: true, name: 'Oui' },
                        { id: false, name: 'Non' },
                    ]}
                /> */}

                <ColoredBooleanField
                    source="isNational"
                    label="resources.users.fields.isNational"
                />
                <SelectField
                    source="promoBox"
                    label="resources.users.fields.promoBox"
                    choices={promoBox}
                />

                {/* <TextField
                        source="phone"
                        label="resources.users.fields.phone"
                    /> */}

                <DateField source="created_at" type="date" locales="fr-FR" />
            </Datagrid>
        </List>
    );
};

export default UserList;

import * as React from 'react';
import { FC } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTranslate, FieldProps } from 'react-admin';
import products from '../data/products';
import { Customer } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import { Record } from 'ra-core';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
    },
    chip: { margin: 4 },
});

interface ProductsProps {
    record?: Record;
    addLabel?: Boolean;
    source?: String;
}

const ProductsField: FC<ProductsProps> = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return record ? (
        <span className={classes.main}>
            {record.products &&
                record.products.map(productId => {
                    const product = products.find(s => s.id === productId);

                    return product ? (
                        <Chip
                            size="small"
                            key={product.id}
                            className={classes.chip}
                            label={translate(product.name)}
                        />
                    ) : null;
                })}
        </span>
    ) : null;
};

ProductsField.defaultProps = {
    addLabel: true,
    source: 'products',
};

export default ProductsField;

import * as React from 'react';
import { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { FieldProps } from '../types';

interface Props extends FieldProps<any> {
    className?: string;
    size?: string;
}

const AvatarField: FC<Props> = ({ record, size = '30', className }) => {
    return record && record.imageUrl ? (
        <Avatar
            variant="rounded"
            src={`${process.env.REACT_APP_PUBLIC_URL}${record.imageUrl}`}
        />
    ) : (
        <Avatar variant="rounded">E</Avatar>
    );
};

export default AvatarField;

import * as React from 'react';
import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToolBar from './ToolBar';
// import WeeklyPacksPerPromoBox from './WeeklyPacksPerPromoBox';
import MonthlyPacksPerPromoBox from './MonthlyPacksPerPromoBox';
// import WeeklyQuantitiesPerPromoBox from './WeeklyQuantitiesPerPromoBox';
import MonthlyQuantitiesPerPromoBox from './MonthlyQuantitiesPerPromoBox';
import { Customer, Order, Review } from '../../types';
interface PromoBox {
    id: string;
    name: string;
}

interface CustomerData {
    [key: string]: Customer;
}

interface State {
    nbNewOrders?: number;
    nbPendingReviews?: number;
    pendingOrders?: Order[];
    pendingOrdersCustomers?: CustomerData;
    pendingReviews?: Review[];
    pendingReviewsCustomers?: CustomerData;
    recentOrders?: Order[];
    revenue?: number;
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '0em',
        // backgroundColor: 'red',
    },
    flex: { display: 'flex' },
    flexColumn: {
        display: 'flex',
        marginBottom: '0em',
        // backgroundColor: 'red',
    },
    leftCol: { flex: 1, marginRight: '0em' },
    rightCol: { flex: 1, marginLeft: '0em' },
    kpiContainer: { padding: '1em' },
    // singleCol: { marginTop: '0.5em', marginBottom: '0.5em' },
}));

// const Spacer = () => <span style={{ width: '1em' }} />;
// const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const CumulPromoBoxDashboard: FC<any> = ({ lastyear = false }) => {
    const [promoBox, setPromoBox] = useState('all');

    const classes = useStyles();

    return (
        <div className={classes.container}>
            {/* <div className={classes.flexColumn}>
                <div className={classes.leftCol}>
                    <div className={classes.kpiContainer}>
                        <ToolBar setPromoBox={setPromoBox} />
                    </div>
                </div>
                <div className={classes.rightCol}></div>
            </div> */}
            <div className={classes.flexColumn}>
                {/* <div className={classes.leftCol}>
                    <div className={classes.kpiContainer}>
                        <WeeklyPacksPerPromoBox
                            promoBox={promoBox}
                            height={200}
                        />
                    </div>
                    <div className={classes.kpiContainer}>
                        <WeeklyQuantitiesPerPromoBox
                            promoBox={promoBox}
                            height={200}
                        />
                    </div>
                </div> */}
                <div className={classes.rightCol}>
                    {/* <div className={classes.kpiContainer}>
                        <MonthlyPacksPerPromoBox
                            promoBox={promoBox}
                            height={250}
                        />
                    </div> */}
                    <div className={classes.kpiContainer}>
                        <MonthlyQuantitiesPerPromoBox
                            promoBox={promoBox}
                            height={250}
                            lastyear={lastyear}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CumulPromoBoxDashboard;

import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    SaveButton,
    FormWithRedirect,
    // required,
    SelectArrayInput,
    usePermissions,
} from 'react-admin';

import { Box, Card, CardContent } from '@material-ui/core';

import Aside from './Aside';

import products from '../data/products';

import FullNameField from './FullNameField';
// import { validatePasswords } from './PromoBoxCreate';
import { Customer, FieldProps } from '../types';

const PromoBoxEdit = (props: any) => {
    return (
        <Edit
            title={<PromoBoxTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <PromoBoxForm />
        </Edit>
    );
};

const PromoBoxTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;

// const validateBl = [blValidation];

const PromoBoxForm = (props: any) => {
    const { permissions } = usePermissions();

    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Card>
                    <form>
                        <CardContent>
                            <Box display={{ md: 'block', lg: 'flex' }}>
                                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >
                                            <TextInput
                                                source="name"
                                                label="resources.promobox.fields.name"
                                                resource="promobox"
                                                disabled
                                                // validate={requiredValidate}

                                                fullWidth
                                            />
                                        </Box>
                                    </Box>

                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box
                                            display={{
                                                xs: 'block',
                                                sm: 'flex',
                                            }}
                                        >
                                            <Box
                                                flex={1}
                                                mr={{ xs: 0, sm: '0.5em' }}
                                            >
                                                <SelectArrayInput
                                                    source="products"
                                                    label="resources.promobox.fields.products"
                                                    choices={products}
                                                    optionText="name"
                                                    optionValue="id"
                                                    alwaysOn
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>

                        {permissions === 'admin' && (
                            <Toolbar
                                record={formProps.record}
                                basePath={formProps.basePath}
                                undoable={false}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                saving={formProps.saving}
                                resource="promobox"
                            >
                                <SaveButton {...formProps} />
                                {/* <DeleteButton {...formProps} /> */}
                            </Toolbar>
                        )}
                    </form>
                </Card>
            )}
        />
    );
};

// const requiredValidate = [required()];

export default PromoBoxEdit;

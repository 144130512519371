import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    CartesianGrid,
    YAxis,
    Legend,
    Tooltip,
    LineChart,
    Line,
} from 'recharts';

// {
/* <LineChart width={500} height={300} data={data}>
    <XAxis dataKey="name"/>
    <YAxis/>
    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
    <LineChart type="monotone" dataKey="uv" stroke="#8884d8" />
    <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
  </LineChart> */
// }

import { PromoBox } from '../../types';

interface PackStats {
    date: String;
    packsCount: number;
}
interface State {
    promoBox?: [PromoBox];
    packStats?: PackStats[];
}
const getCardTitle = lastyear => {
    let year = new Date().getFullYear();
    if (lastyear) year -= 1;

    return `Cumul des quantités saisies ${year}`;
};
const CustomTooltip = ({ active, payload, label, packStats }: any) => {
    if (active && payload && payload.length) {
        // compute month's value:
        const p = payload[0].payload;
        let monthValue = payload[0].value;
        if (p.id > 1) {
            const lastMonthPayload = packStats[p.id - 2];
            monthValue -= lastMonthPayload['Quantité'];
        }
        return (
            <div style={{ background: 'white' }}>
                <p className="label">{`${label} : ${monthValue}`}</p>
                <p className="value">{`Cumul : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};
const MonthlyQuantitiesPerPromoBox = ({
    promoBox,
    height = 250,
    lastyear = false,
}) => {
    const [packStats, setPackStats] = useState([]);

    const version = useVersion();
    const dataProvider = useDataProvider();

    const fetchStats = useCallback(async () => {
        const { data: packStats } = await dataProvider.getList(
            'packs/cumulpromoBox/monthlyqtystats',
            {
                filter: { promoBox, lastyear },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 50 },
            }
        );

        setPackStats(
            packStats.map(pS => {
                pS['Quantité'] = pS['PHARMACARE'] + pS['DS PHARMA'];

                return pS;
            })
        );
    }, [dataProvider, promoBox]);

    useEffect(() => {
        fetchStats();
    }, [version, promoBox]);

    return (
        <Card>
            <CardHeader
                // action={
                //     <FormControl variant="outlined">
                //         <InputLabel id="demo-simple-select-outlined-label">
                //             Région
                //         </InputLabel>
                //         <Select
                //             labelId="promoBox-label"
                //             id="select"
                //             label="Région"
                //             onChange={handlePromoBoxChange}
                //         >
                //             <MenuItem key={'all'} value={'all'}>
                //                 Toutes les régions
                //             </MenuItem>
                //             {(() => {
                //                 return promoBox.map(r => {
                //                     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                //                     return (
                //                         <MenuItem key={r.id} value={r.id}>
                //                             {r.name}
                //                         </MenuItem>
                //                     );
                //                 });
                //             })()}
                //         </Select>
                //     </FormControl>
                // }
                title={getCardTitle(lastyear)}
                subheader="Par mois"
            />

            <CardContent>
                <ResponsiveContainer width="100%" height={height}>
                    <LineChart
                        width={150}
                        height={150}
                        data={packStats}
                        margin={{
                            top: 15,
                            right: 15,
                            left: 15,
                            bottom: 25,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="month"
                            label={{
                                value: '',
                                position: 'insideBottomRight',
                                offset: -1,
                            }}
                        />
                        <XAxis
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            // tick={renderQuarterTick}
                            height={100}
                            scale="band"
                            // xAxisId="quarter"
                            // label={{
                            //     value: '',
                            //     angle: -90,
                            //     position: 'insideLeft',
                            // }}
                        />
                        <YAxis />
                        <Tooltip
                            itemSorter={item => -item.value}
                            content={<CustomTooltip packStats={packStats} />}
                        />
                        <Legend />

                        <Line
                            fill="#8884d8"
                            dataKey={'Quantité'}
                            stroke="#8884d8"
                        />
                    </LineChart>
                    {/* <BarChart
                        width={150}
                        height={150}
                        data={packStats}
                        margin={{
                            top: 15,
                            right: 15,
                            left: 15,
                            bottom: 25,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="month"
                            label={{
                                value: '',
                                position: 'insideBottomRight',
                                offset: -1,
                            }}
                        />
                        <XAxis
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            // tick={renderQuarterTick}
                            height={100}
                            scale="band"
                            // xAxisId="quarter"
                            // label={{
                            //     value: '',
                            //     angle: -90,
                            //     position: 'insideLeft',
                            // }}
                        />
                        <YAxis />
                        <Tooltip itemSorter={item => -item.value} />
                        <Legend />

                        <Bar
                            dataKey={'PHARMACARE'}
                            fill="#5234eb"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'DS PHARMA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                    </BarChart> */}
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default MonthlyQuantitiesPerPromoBox;

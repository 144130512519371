import React, { useState, useCallback } from 'react';
import {
    Button,
    SaveButton,
    SimpleForm,
    FileInput,
    required,
    FileField,
    Toolbar,
    useNotify,
    useDataProvider,
    fetchEnd,
    fetchStart,
    CREATE,
    useRefresh,
} from 'react-admin';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import { useFormState } from 'react-final-form';

const RemovePacksButton = props => {
    const { handleCloseDialog, filterValues } = props;
    const [btnDisabled, setBtnDisabled] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    // const formState = useFormState();
    const dataProvider = useDataProvider();
    const handleClick = useCallback(() => {
        // if (!formState.valid) {
        //     return;
        // }
        console.log('handleClick');
        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();
        setBtnDisabled(true);

        dataProvider(CREATE, 'packs/remove', {
            data: { $filter: filterValues },
        })
            .then(response => {
                const { data } = response;
                const count = data && data.count ? data.count : 0;
                // Refresh the choices of the ReferenceInput to ensure our newly
                notify(`${count} Demandes supprimées`, 'info', {
                    smart_count: 1,
                });
            })
            .catch(error => {
                const errorMsg = error.message
                    ? error.message
                    : 'Validation non effectuée';
                notify(errorMsg, 'warning');
            })
            .finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                fetchEnd();
                setBtnDisabled(false);
                //close dilog
                handleCloseDialog();
                refresh();
            });
    }, [dataProvider, notify, handleCloseDialog]);

    return (
        <SaveButton
            {...props}
            disabled={btnDisabled}
            icon={<HighlightOffIcon />}
            handleSubmitWithRedirect={handleClick}
        />
    );
};

export default props => {
    // const { selectedIds } = props;
    // userIds = selectedIds;
    const [showDialog, setShowDialog] = React.useState(false);

    const handleOpenDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => setShowDialog(false);

    const NotifCreateToolbar = props => (
        <Toolbar {...props} style={{ backgroundColor: 'white' }}>
            <RemovePacksButton
                handleCloseDialog={handleCloseDialog}
                label="Supprimer"
                submitOnEnter={false}
                variant="text"
                filterValues={props.filterValues}
            />
            <Button label="Annuler" variant="text" onClick={handleCloseDialog}>
                <IconCancel />
            </Button>
        </Toolbar>
    );

    return (
        <>
            <Button onClick={handleOpenDialog} label="Supprimer">
                <HighlightOffIcon />
            </Button>

            <Dialog
                fullWidth
                open={showDialog}
                // onClose={handleCloseDialog}
                aria-label="Importer des utilisateurs"
            >
                <DialogTitle>
                    Supprimer Toutes les "Nouvelles" demandes Sélectionnées
                </DialogTitle>
                <DialogContent>
                    {/* <Create basePath={'/users'} resource={'users'}> */}
                    {/* <SimpleForm
                        submitOnEnter={false}
                        // resource="users"
                        // save={handleSubmit}
                        toolbar={<NotifCreateToolbar />}
                    >
                    </SimpleForm> */}
                </DialogContent>
                <DialogActions>
                    <NotifCreateToolbar {...props} />
                </DialogActions>
            </Dialog>
        </>
    );
};

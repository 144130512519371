import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
// import SettingsIcon from '@material-ui/icons/Settings';
// import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    usePermissions,
} from 'react-admin';

// import admins from '../admins';
import users from '../users';
// import magasiniers from '../magasiniers';

import packs from '../packs';

import archive from '../archive';

import promobox from '../promoBox';

// import SubMenu from './SubMenu';
import { AppState } from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuUsers' | 'menuAnnuaire';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const { permissions } = usePermissions();
    const visibility = localStorage.getItem('visibility');
    // const [state, setState] = useState({
    //     menuCatalog: false,
    //     menuSales: false,
    //     menuUsers: false,
    //     menuAnnuaire: false,
    // });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    // const handleToggle = (menu: MenuName) => {
    //     setState(state => ({ ...state, [menu]: !state[menu] }));
    // };

    return (
        <div>
            {/* only for admin */}
            {(permissions === 'admin' || permissions === 'user') && (
                <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            )}
            {/* {permissions === 'admin' && (
                <SubMenu
                    handleToggle={() => handleToggle('menuUsers')}
                    isOpen={state.menuUsers}
                    sidebarIsOpen={open}
                    name="pos.menu.users"
                    icon={<admins.icon />}
                    dense={dense}
                >
                    <MenuItemLink
                        to={`/admins`}
                        primaryText={translate(`resources.admins.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<admins.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/magasiniers`}
                        primaryText={translate(`resources.magasiniers.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<magasiniers.icon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )} */}
            {permissions === 'admin' && (
                <MenuItemLink
                    to={`/users`}
                    primaryText={translate(`resources.users.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {(permissions === 'admin' || permissions === 'user') && (
                <MenuItemLink
                    to={`/packs`}
                    primaryText={translate(`resources.packs.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<packs.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {(permissions === 'admin' || permissions === 'user') && (
                <MenuItemLink
                    to={`/archive`}
                    primaryText={translate(`resources.archive.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<archive.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {permissions === 'admin' && (
                <MenuItemLink
                    to={`/promobox`}
                    primaryText={translate(`resources.promobox.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<promobox.icon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            )}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;

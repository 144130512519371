import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { Card, CardHeader, CardContent } from '@material-ui/core';

import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    CartesianGrid,
    YAxis,
    Tooltip,
    Legend,
} from 'recharts';

import { Grossiste } from '../../types';

interface PackStats {
    date: String;
    packsCount: number;
}
interface State {
    grossistes?: [Grossiste];
    packStats?: PackStats[];
}
const getCardTitle = lastyear => {
    let year = new Date().getFullYear();
    if (lastyear) year -= 1;

    return `Quantités saisies ${year}`;
};
const MonthlyQuantitiesPerGrossiste = ({
    grossiste,
    height = 250,
    lastyear = false,
}) => {
    const [packStats, setPackStats] = useState([]);

    const version = useVersion();
    const dataProvider = useDataProvider();

    const fetchStats = useCallback(async () => {
        const { data: packStats } = await dataProvider.getList(
            'packs/grossiste/monthlyqtystats',
            {
                filter: { grossiste, lastyear },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 50 },
            }
        );

        setPackStats(packStats);
    }, [dataProvider, grossiste]);

    useEffect(() => {
        fetchStats();
    }, [version, grossiste]);

    return (
        <Card>
            <CardHeader
                // action={
                //     <FormControl variant="outlined">
                //         <InputLabel id="demo-simple-select-outlined-label">
                //             Région
                //         </InputLabel>
                //         <Select
                //             labelId="grossiste-label"
                //             id="select"
                //             label="Région"
                //             onChange={handleRegionChange}
                //         >
                //             <MenuItem key={'all'} value={'all'}>
                //                 Toutes les régions
                //             </MenuItem>
                //             {(() => {
                //                 return grossistes.map(r => {
                //                     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                //                     return (
                //                         <MenuItem key={r.id} value={r.id}>
                //                             {r.name}
                //                         </MenuItem>
                //                     );
                //                 });
                //             })()}
                //         </Select>
                //     </FormControl>
                // }
                title={getCardTitle(lastyear)}
                subheader="Par mois"
            />

            <CardContent>
                <ResponsiveContainer width="100%" height={height}>
                    <BarChart
                        width={150}
                        height={50}
                        data={packStats}
                        margin={{
                            top: 15,
                            right: 15,
                            left: 15,
                            bottom: 25,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="month"
                            label={{
                                value: '',
                                position: 'insideBottomRight',
                                offset: -1,
                            }}
                        />
                        <XAxis
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            interval={0}
                            // tick={renderQuarterTick}
                            height={100}
                            scale="band"
                            // xAxisId="quarter"
                            // label={{
                            //     value: '',
                            //     angle: -90,
                            //     position: 'insideLeft',
                            // }}
                        />
                        <YAxis />
                        <Tooltip itemSorter={item => -item.value} />
                        {/* <Legend /> */}

                        <Bar
                            dataKey={'BUMAGECO'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'COOPER BENISNASSEN S.A'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'COOPER CHAOUIA-OUARDIGHA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'COOPER DISMEDIC'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'COOPER ORIENTAL MEDICA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'COOPER PHARMA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'COOPER SOPHADET'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'COOPER TENSIFT'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'COPHAG'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />

                        <Bar
                            dataKey={'COPHAG'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'CPM'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'CPRE'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'DIPHARM'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'DISPHANAD'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'DISPHAT'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'DISTRIPHAK'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'GDIS'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'GIPHAR-MAROC'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'GLOBALDIS SA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'GPF'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />

                        <Bar
                            dataKey={'GPM'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'Grossiste Pharma EST'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'Grossiste Pharma RIF'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'KENIPHARMA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'LODIMED S.A'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'MAREPHA SA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'MEDICAMENTS DISTRIBUTION 5'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'MEDICOL'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'MEDIREP'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'OUEST REPARTITION PHARMACEUTIQUE'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'PHARMA COOP'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'PHARMAMEK'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'PHARMANORD'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'PHARMASMIR'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'RECAMED'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'REPHAK'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'REPHAL SARL'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'REPHAR S.A.'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SAHARA REPARTITION DE MEDICAMENTS'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SERP MARRAKECH'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHA PORTO'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHACA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHACENTRE'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHACHARK'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHADIM'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHAFAS'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHAGHARB'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHANORD'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHASAIS'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHASALE'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOPHATLANTIC'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOREMED'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SOREPHA'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={
                                'STÉ AFRICAINE DE RÉPARTITION PHARMACEUTIQUE (SARP)'
                            }
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'TADLAPHARM'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'UGP'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'UNIFARMA  S.A'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'UPHAR'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'UPM'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                        <Bar
                            dataKey={'SDM Pharma'}
                            fill="#82ca9d"
                            // label={{ position: 'top' }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default MonthlyQuantitiesPerGrossiste;
